<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('vehicles.title')"
      :paths="[{title:$t('settings.title'),link:'settings'}]"

      :btn-title="$t('vehicles.new_btn')"

      :btn='true'
      @btnAction='showModal = true;modalData=null'
    />

    <div>
      <filter-header
        :content='filterContent'
        @applyFilter='applyFilter'
        @resetFilter='resetFilter'
        @orderApplicants='orderData'

      />
    </div>
    <section
      class='
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      '>
      <div class='d-flex flex-wrap flex-1'>
        <b-col lg='12' md='12' class='px-0'>

          <div class='w-table  min-height-unset d-flex flex-column'>
            <w-tables
              :noDataTitle="$t('table.no_data_title')"
              :noDataSubtitle="$t('table.no_data_subtitle')"
              class='row-move '
              v-if='getData'
              :per-page='filterData.per_page'
              :items='getData'
              :fields='tableFields'
              :custimized-items="[
                { name: 'name' },
                { name: 'status' },
                { name: 'action' },
              ]"
            >

              <template slot='name' slot-scope='{ data }'>

                <name-tile
                  :name='data.item.name'
                  :image='data.item.image'
                  :data-id='data.item.id'
                />
              </template>


              <template slot='status' slot-scope='{ data }'>
                <wameed-switch
                  :class="data.item.can_delete ===1 ?'disabled':''"
                  :checked='data.item.status ===1'
                  @onChange='(e)=>{e.preventDefault(); onChange(data.item)}'
                />
              </template>

              <template slot='action' slot-scope='{ data }'
                        v-if='data.item.type==null'>
                <div class='table-option order-handler'>
                  <div class='order'>

                    <o-order-icon />
                  </div>
                </div>
                <b-dropdown
                  variant='background'
                  class='table-option'
                  toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                  menu-class='rounded-8'
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon />
                  </template>

                  <b-dropdown-item
                    class='permission-edit-item'
                    v-permission='[$route.meta.pagePermissions.edit]'
                    @click='()=> openUpdateModal(data.item)'
                  >
                    <span class='text-regular-14 text-font-secondary'>

                      <edit-icon class='mx-2' />
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if='data.item.can_delete!==1'
                    class='permission-delete-item'
                    v-permission='[$route.meta.pagePermissions.delete]'
                    @click='deleteModal = true;  deleteModalData= data.item'>
                    <span class='text-regular-14  text-danger'>
                      <trash-icon class='mx-2 ' />
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg='12' md='12' class='px-0'>
          <wameed-pagination
            v-if='getTotal'
            v-model='filterData.page'
            :page='filterData.page'
            :total-items='getTotal.totalItems'
            :per_page='filterData.per_page'
            :menu-title="$t('common.show_results')"
            @changePage='changePage'
            @changeCurrentPage='changeCurrentPage'
          />
        </b-col>

      </div>

    </section>

    <vehicle-modal
      :visible='showModal'
      @close='showModal = false'
      :modal-data='modalData'
      @onComplete='()=>loadData()'
    />


    <warning-modal
      variant='main'
      iconName='o-toggle-icon'
      :visible='showDisabledModal'
      @close='showDisabledModal = false'
      @submitAction='showDisabledModal = false'
      :title="$t('vehicles.modal.disable')"
      :subTitle="$t('vehicles.modal.disable_desc')"
      :btnTitle="$t('btn.ok')"
    />

    <warning-modal
      variant='danger'
      iconName='trash-icon'
      :visible='deleteModal'
      @close='deleteModal = false'
      @submitAction='deleteAction'
      :title="$t('vehicles.modal.delete')"
      :subTitle="$t('vehicles.modal.delete_desc')"
      :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import Sortable from 'sortablejs';

import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from '@/components/WarningModal';

import { WameedPagination, WameedSwitch, WameedTables as WTables } from 'wameed-ui/dist/wameed-ui.esm';
import NameTile from '@/components/nameTile.table';
import vehicleModal from '@/views/pages/settings/vehicles/components/vehicleModal';

export default {
  components: {
    vehicleModal,
    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      showDisabledModal: false,
      deleteModalData: null,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: ''
      },
      filterContent: [
        {
          title: this.$i18n.t('table.availability'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.available')
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_available')
            }
          ]
        }
      ],
      tableFields: [

        {
          key: 'name',
          label: this.$i18n.t('vehicles.form.name'),
          sortable: false,
          tdClass: 'w-2/5'
        },

        {
          key: 'min_delivery_price',
          label: this.$i18n.t('settings.general_setting.min_delivery'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'add_price_per_km',
          label: this.$i18n.t('settings.general_setting.add_per_km'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/vehicles/getData',
      getTotal: 'admin/settings/vehicles/getTotal'
    })
  },
  created() {
    this.loadData();
  },
  mounted() {

    let order = this.order;
    var el = document.querySelector('tbody[role=\'rowgroup\']');
    // var sortable = Sortable.create(el);
    Sortable.create(el, {
      group: 'name',  // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
      easing: 'cubic-bezier(1, 0, 0, 1)', // Easing for animation. Defaults to null. See https://easings.net/ for examples.
      handle: '.order-handler',  // Drag handle selector within list items


      // Element dragging ended
      onEnd: function(/**Event*/evt) {

        let id = evt.item.querySelector('div[data-id]').getAttribute('data-id');
        order(id, evt.oldIndex, evt.newIndex);

      }


    });
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/vehicles/get',
      _deleteData: 'admin/settings/vehicles/delete'
    }),


    order(id, oldIndex, newIndex) {
      this.$store.dispatch('admin/settings/vehicles/reorder', {
        id: id,
        old_order: oldIndex,
        new_order: newIndex,
        page: this.filterData.page,
        per_page: this.filterData.per_page
      });
    },
    openUpdateModal(item) {

      this.$store.dispatch('admin/settings/vehicles/detail', { id: item.id }).then((items) => {
        this.showModal = true;
        this.modalData = {
          id: item.id,
          icon: { name: item.image },
          min_delivery_price: item.min_delivery_price,
          add_price_per_km: item.add_price_per_km
        };
        items.forEach((item) => {
          this.modalData['name_' + item['locale']] = item['name'];
        });
      });

    },

    onChange(item) {
      if (item.can_delete === 1) {
        this.showDisabledModal = true;
        return;
      }
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }

      this.$store.dispatch('admin/settings/vehicles/toggle', { id: item.id, status: status });
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },

    deleteAction() {
      this.deleteModal = false;
      this._deleteData({ id: this.deleteModalData.id });
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item
      };
      this.loadData();
    }
  }
};
</script>
